/* eslint-disable react/no-danger */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { ContentHtmlLegacy } from '../ContentHtmlLegacy';
import { dateService } from '../../../domain/services/dateService';
import { useGeoLocation } from '../../../hooks/useGeoLocation';
import { appendInlineScript } from '../../../domain/utils/domUtils';
import { WithCmpSettingsNonBlocking } from '../../WithCmpSettingsNonBlocking';
import {
  addAttributeToTag,
  getExternalLinks
} from '../../../domain/utils/stringUtils';
import { OptanonButton } from '../../responsive/atoms/OptanonButton';
import { consentService } from '../../../domain/services/consentService';
import { ParagraphWrapper } from '../ParagraphWrapper';

const ContentHtmlInner = ({ data, cmpSettings }) => {
  const tagRef = useRef(null);

  const style = {
    wrapper: css`
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 4px #000;
      padding: 15px;
      margin-bottom: 15px;
    `,
    button: css`
      display: flex;
      justify-content: center;
    `
  };

  // TODO Remove ContentHtmlLegacy when all paragraphs have their fields split.
  if (
    (!cmpSettings ||
      consentService.getCookiePermissions(data.cookieType, cmpSettings)) &&
    data.body &&
    ((data.body.indexOf('<script') !== -1 &&
      data.body.indexOf('text/json') === -1) ||
      // @todo confirm if there is any body with AMP tags
      data.body.indexOf('<amp-') !== -1)
  ) {
    return <ContentHtmlLegacy data={data} />;
  }

  const isVisible =
    useGeoLocation(data.geolocationFilter, data.countries) &&
    dateService.isWithinDateRange(
      Math.floor(new Date().getTime() / 1000),
      data.startDate?.timestamp,
      data.endDate?.timestamp
    );
  useEffect(() => {
    if (data.script && tagRef.current) {
      appendInlineScript(tagRef.current, data.script);
    }
  }, [isVisible, tagRef.current]);

  if (
    (!cmpSettings ||
      consentService.getCookiePermissions(data.cookieType, cmpSettings)) &&
    isVisible &&
    (data.body || data.script)
  ) {
    let updatedHtml = data.body;
    const externalLinks = getExternalLinks(data.body);
    externalLinks.forEach(link => {
      const originalOpeningTag = link.openingTag;
      addAttributeToTag(link, 'rel', ['noopener', 'noreferrer', 'nofollow']);
      updatedHtml = data.body.replace(originalOpeningTag, link.openingTag);
    });
    return (
      <div ref={tagRef} dangerouslySetInnerHTML={{ __html: updatedHtml }} />
    );
  }

  if (
    cmpSettings &&
    !consentService.getCookiePermissions(data.cookieType, cmpSettings) &&
    isVisible &&
    data.cookieTypeFallback
  ) {
    // @todo - use <CookieConsent/>
    return (
      <div css={style.wrapper}>
        <div>
          <span
            ref={tagRef}
            dangerouslySetInnerHTML={{ __html: data.cookieTypeFallback }}
          />
          <div css={style.button}>
            <OptanonButton color="#333" isLink />
          </div>
        </div>
      </div>
    );
  }

  return <div />;
};

ContentHtmlInner.defaultProps = {
  cmpSettings: undefined
};

ContentHtmlInner.propTypes = {
  data: PropTypes.shape({
    geolocationFilter: PropTypes.string,
    countries: PropTypes.arrayOf(PropTypes.any),
    body: PropTypes.string,
    script: PropTypes.string,
    startDate: PropTypes.objectOf(PropTypes.any),
    endDate: PropTypes.objectOf(PropTypes.any),
    cookieType: PropTypes.string,
    cookieTypeFallback: PropTypes.string
  }).isRequired,
  cmpSettings: PropTypes.objectOf(PropTypes.any)
};

export const ContentHtml = ParagraphWrapper(
  WithCmpSettingsNonBlocking(ContentHtmlInner)
);
